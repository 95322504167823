<template>
    <div class="fill full-height-layout">
        <template v-if="mergedOptions.pagination && mergedOptions.paginationType !== 'native'">
            <div class="d-flex justify-content-end align-items-center text-grey">
                <p class="text-grey">Page <span ref="currentPage"></span> of <span ref="totalPages"></span></p>
                <mercur-button class="btn btn-icon btn-icon-sm" @click="toPreviousPage"><i class="fas fa-angle-left text-grey" /></mercur-button>
                <mercur-button class="btn btn-icon btn-icon-sm" @click="toNextPage"><i class="fas fa-angle-right text-grey" /></mercur-button>
            </div>
        </template>
        <div :is="mergedOptions.elementTag" class="fill full-height-layout">
            <div class="grid-wrapper c-standard-table fill full-height-layout">
                <ag-grid-vue class="fill full-height-layout ag-theme-material border"
                     :columnDefs="columnDefs"
                     :rowModelType="rowModel"
                     :rowData="items"
                     :gridOptions="gridOptions"
                     @model-updated="onModelUpdated"
                     @grid-ready="onGridReady"
                     @pagination-changed="onPaginationChanged"
                     @revoked="refreshGrid"
                     @rowClicked="rowClicked"
                     @selection-changed="onSelectionChanged"
                     @row-selected="onRowSelected"
                >
                </ag-grid-vue>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Available props
 * {
 *     {url} (String) API endpoint to call for fetching data.
 *     {options} (object) Configuration options
 *          ...
 *          {actions} (array of objects)
 *              Example:
 *              {
 *                  icon: 'remove_red_eye',
 *                  text: 'View',
 *                  to: params => ({
 *                      name: 'SupplierProducts',
 *                      params: {
 *                          supplierId: params.data.supplierId,
 *                      },
 *                  }),
 *              },
 *              {
 *                  icon: 'edit',
 *                  text: 'Edit',
 *                  to: '/edit',
 *              },
 *              {
 *                  icon: 'custom',
 *                  text: 'Custom actions',
 *                  onClick: function (params) {
 *                      this.customAction(params.entityId)
 *                  }
 *              },
 *          ...
 *
 * }
 */
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import StatusChip from '@/components/utils/StatusChip'

import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'
import { getDeepProperty } from './utils/Utils'

export default {
    name: 'DataTable',
    components: {
        StatusChip,
        AgGridVue,
    },
    data () {
        return {
            columnDefs: [],
            gridOptions: null,
            defaultOptions: {
                isSelectable: false,
                pagination: true,
                paginationPageSize: 50,
                paginationType: 'native',
                quickSearchDelay: 500,
                quickSearchColumns: [],
                rowSelection: 'multiple',
                suppressCellSelection: false,
                suppressRowClickSelection: true,
                suppressColumnVirtualisation: true,
                suppressPropertyNamesCheck: true,
                suppressCopyRowsToClipboard: true,
                enableRangeSelection: true,
                elementTag: 'div',
                sortModel: {},
                actionsWidth: 60,
            },
            quickSearchTimerId: null,
        }
    },

    props: {
        url: null,
        items: {
            type: Array,
            default: () => [],
        },
        options: {
            required: false,
            type: Object,
            default: () => ({}),
        },
        quickSearch: {
            required: false,
            type: String,
            default: null,
        },
        quickFilter: {
            required: false,
            type: String,
            default: null,
        },
        selectedRows: {
            required: false,
            type: Array,
            default: () => [],
        },
    },

    watch: {
        quickSearch: {
            handler (value) {
                if (!this.mergedOptions.quickSearchColumns.length) {
                    return
                }
                if (this.quickSearchTimerId) {
                    clearTimeout(this.quickSearchTimerId)
                }
                this.quickSearchTimerId = setTimeout(() => {
                    if (!value) {
                        this.gridApi.setFilterModel(null)
                        return
                    }

                    const filterModel = this.mergedOptions.quickSearchColumns.reduce((accum, k) => {
                        accum[k] = {
                            type: 'contains',
                            filter: value,
                            filterType: 'text',
                        }
                        return accum
                    }, {})

                    this.gridApi.setFilterModel(filterModel)

                    this.quickSearchTimerId = null
                }, this.mergedOptions.quickSearchDelay)
            },
        },
        quickFilter (value) {
            this.gridApi.setQuickFilter(value)
        },
        url () {
            this.applyDataSource()
            this.$nextTick(() => {
                this.refreshGrid()
            })
        },
        mergedOptions: {
            handler () {
                this.generateColumns()
                this.generateRowActionsCell()
            },
            deep: true,
        },
    },

    computed: {
        rowModel () {
            if (this.url) {
                return 'serverSide'
            }
            return 'clientSide'
        },
        mergedOptions () {
            return {
                ...this.defaultOptions,
                ...this.options,
            }
        },
    },

    methods: {
        onGridReady (params) {
            this.$emit('gridReady', params)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            if (this.mergedOptions.pagination && this.mergedOptions.paginationType !== 'native') {
                this.onPaginationChanged()
            }

            if (this.mergedOptions.sortModel) {
                this.gridApi.setSortModel(this.mergedOptions.sortModel)
            }

            if (this.mergedOptions.filterModel) {
                this.gridApi.setFilterModel(this.mergedOptions.filterModel)
            }

            if (this.rowModel === 'clientSide') {
                return
            }
            this.applyDataSource()
        },
        applyParams (params) {
            this.serverSideDatasource.params = params
        },
        applyDataSource (params = null) {
            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.url,
                responseHandler: (successCallback, { data }) => {
                    this.gridApi.hideOverlay()

                    if (data === null || data.items === null || data.items.length === 0) {
                        this.gridApi.showNoRowsOverlay()
                        this.$emit('fetched', [])
                        return
                    }
                    const totalRows = data.items[0].totalRows

                    if (this.mergedOptions.onDataReceived) {
                        this.mergedOptions.onDataReceived(data.items, totalRows)
                    }

                    successCallback(data.items, totalRows)
                    this.$emit('fetched', data.items)

                    setTimeout(() => {
                        this.gridApi.sizeColumnsToFit()
                    }, 100)
                },
                params,
            })
            this.gridApi.setServerSideDatasource(this.serverSideDatasource)
        },
        generateColumns () {
            this.columnDefs = []
            if (!this.mergedOptions.columns) {
                return
            }
            Object.entries(this.mergedOptions.columns).forEach(([headerName, item]) => {
                let column = {
                    headerName,
                    field: item.field,
                    ...item,
                }

                if (Array.isArray(item.field)) {
                    column.valueFormatter = (params) => item.field.map(fieldName => params.data[fieldName]).join(' ')
                    column.field = item.field[0]
                }

                if (item.link || item.onClick) {
                    column.cellRendererFramework = 'RouterlinkCell'
                    column.cellRendererParams = (params) => {
                        const cellRendererParams = {
                            routerlink: {},
                        }

                        if (item.link) {
                            cellRendererParams.routerlink.to = item.link(params.value, params.data, params)
                        }
                        if (item.icon) {
                            cellRendererParams.icon = item.icon(params.data)
                            cellRendererParams.tooltipText = item.tooltipText()
                        }
                        if (item.onClick) {
                            cellRendererParams.routerlink.onClick = item.onClick
                        }

                        return cellRendererParams
                    }
                }

                if (item.statusChip === true) {
                    column.cellRendererFramework = StatusChip
                }

                this.columnDefs.push(column)
            })

            if (this.mergedOptions.isSelectable) {
                this.columnDefs[0].checkboxSelection = true
            }

            setTimeout(() => {
                this.gridColumnApi.resetColumnState()
            }, 0)
        },
        generateRowActionsCell () {
            if (!this.mergedOptions.actions) {
                return
            }
            let width = this.mergedOptions.actionsWidth
            if (this.mergedOptions.actions.length !== 1) {
                const icons = this.mergedOptions.actions.filter((e) => e.isIcon)
                width = (this.mergedOptions.actions.length > icons.length ? icons.length + 1 : icons.length) * 60
            }

            this.columnDefs.push({
                headerName: '',
                pinned: 'right',
                width: width,
                maxWidth: width,
                minWidth: width,
                cellRendererFramework: 'ActionsCell',
                cellRendererParams: () => {
                    return {
                        actions: [
                            ...this.mergedOptions.actions,
                        ],
                    }
                },
                suppressMenu: true,
                suppressColumnFilter: true,
                sortable: false,
                cellClass: ['ag-cell--actions'],
            })
        },
        selectAllVisibleRows (maxRows = null) {
            const newValue = this.gridApi.getSelectedRows().length === 0
            let nrSelected = 0
            this.gridApi.forEachNode(row => {
                if (maxRows && (maxRows >= 0 && nrSelected >= maxRows)) {
                    return
                }
                row.setSelected(newValue)
                if (newValue) {
                    nrSelected++
                }
            })
        },
        deselectAllRows () {
            this.gridApi.deselectAll()
        },
        refreshGrid () {
            this.gridApi.deselectAll()
            this.gridApi.purgeServerSideCache()
        },
        onModelUpdated (params) {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 2000)
        },
        rowClicked (params) {
            this.$emit('rowClicked', params.data)
        },
        applyFilter (filter) {
            this.gridApi.setFilterModel(filter)
        },
        toPreviousPage () {
            this.gridApi.paginationGoToPreviousPage()
        },
        toNextPage () {
            this.gridApi.paginationGoToNextPage()
        },
        onPaginationChanged () {
            if (this.gridApi && this.$refs.currentPage && this.$refs.totalPages) {
                this.$refs.currentPage.innerHTML = this.gridApi.paginationGetCurrentPage() + 1
                this.$refs.totalPages.innerHTML = this.gridApi.paginationGetTotalPages()
            }
        },
        onSelectionChanged () {
            this.$emit('update:selectedRows', this.gridApi.getSelectedRows())
        },
        onRowSelected (params) {
            this.$emit('onRowSelected', params)
        },
    },

    beforeMount () {
        this.gridOptions = {
            context: this,
            ...this.mergedOptions,
            suppressPaginationPanel: this.mergedOptions.paginationType !== 'native',
            defaultColDef: {
                sortable: getDeepProperty('mergedOptions.defaultColDef.sortable', this, false),
                filter: 'agTextColumnFilter',
                filterParams: {
                    equalsWithNulls: true,
                    filterOptions: ['equals', 'notEqual', 'greaterThan'],
                    defaultOption: 'equals',
                    applyButton: true,
                    newRowsAction: 'keep',
                    clearButton: true,
                },
                menuTabs: ['generalMenuTab', 'filterMenuTab'],
                resizable: true,
                cellClass: 'c-standard-table__cell',
            },
        }

        this.generateColumns()
        this.generateRowActionsCell()
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi
    },
}
</script>
